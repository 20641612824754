.category-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 80%;
    height: 100%;
    max-width: 1200px;
    margin: auto;

    position: relative;


    .entry-single,
    .mobile-entry,
    .entry {
        //width: 30%;
        // height: 500px;
        // background-color: red;
        cursor: pointer;
        display: block;
        background-size: cover;
        background-position: center;
        margin: 2%;
        position: relative;
        width: 30%;
        top: 200px;

        &:before{ 
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b68edf+60,b68edf+100&0+60,1+99 */
background: -moz-linear-gradient(top, rgba(182,142,223,0) 60%, rgba(182,142,223,1) 99%, rgba(182,142,223,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(182,142,223,0) 60%,rgba(182,142,223,1) 99%,rgba(182,142,223,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(182,142,223,0) 60%,rgba(182,142,223,1) 99%,rgba(182,142,223,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b68edf', endColorstr='#b68edf',GradientType=0 ); /* IE6-9 */
        }


        .entry-content {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;

            .entry-hover {
                position: absolute;
                left: 0;
                bottom: 0px;
                height: auto;


                -webkit-transition: all .5s ease-in-out;
                -moz-transition: all .5s ease-in-out;
                -ms-transition: all .5s ease-in-out;
                -o-transition: all .5s ease-in-out;
                transition: all .5s ease-in-out;

                h2 {
                    color: white;
                    text-transform: uppercase;
                    font-size: 100pt;
                    color: white;
                    font-weight: 200;
                    margin: 0;
                    text-align: left;
                    padding: 20px;
                    padding-bottom: 0;
                    width: auto;
                    line-height: 30pt;
                }

                p {
                    color: white;
                    padding: 20px;
                    padding-bottom: 0;
                    // position: absolute;
                    // bottom: 0;
                    width: 63%;
                    opacity: 0;
                    max-height: 0;
                    overflow: hidden;

                    -webkit-transition: all .5s ease-in-out;
                    -moz-transition: all .5s ease-in-out;
                    -ms-transition: all .5s ease-in-out;
                    -o-transition: all .5s ease-in-out;
                    transition: all .5s ease-in-out;
                }
            }
        }

        .icon-play {
            width: 20%;
            margin-bottom: 20px;
            margin-right: 20px;
            height: 100px;
            display: block;
            position: absolute;
            right: -100px;
            bottom: 0;
            background-image: url(../../img/play-btn.svg);
            background-size: contain;
            background-repeat: no-repeat;
            // background-position: center;
            background-position: bottom center;

            -webkit-transition: all .25s ease-in-out .25s;
            -moz-transition: all .25s ease-in-out .25s;
            -ms-transition: all .25s ease-in-out .25s;
            -o-transition: all .25s ease-in-out .25s;
            transition: all .25s ease-in-out .25s;
        }

        &:hover {
            .entry-hover {
                p {
                    max-height: 300px;
                    opacity: 1;
                    // color:red; 
                }
            }

            .icon-play {
                right: 0;
            }
        }

    }

    .mobile-vid {
        width: 100%;
        // height: auto;
        // height: calc(100% - 200px);
        height: calc(100% - 280px);
        margin-top: 80px;
    }
}

.desktop-categories {
    display: block;
}

.mobile-categories {
    display: none;
}


@media (max-width: 1200px) {
    .category-content {
        width: 90%;

        .entry {
            .entry-content {
                .entry-hover {
                    h2 {
                        font-size: 70pt;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .desktop-categories {
        display: none;
    }

    .mobile-categories {
        display: block;
    }

    .category-content {
        width: 100%;

        .mobile-entry,
        .entry {
            cursor: pointer;
            display: block;
            background-size: cover;
            background-position: center;
            margin: 0;
            position: relative;
            width: 100%;
            height: 100%;

            top: 0;

            .entry-content {
                background-color: #AFAAC9;
                background-color: $purple-light;

                .entry-hover {
                    height: 200px;
                    background-color: $purple-dark;
                    width: 100%;

                    h2 {
                        font-size: 40pt;
                    }

                    p {
                        max-height: 300px;
                        opacity: 1;
                        margin: 0;
                        padding-bottom: 0;
                        // color:red; 
                    }
                }
            }




            .icon-play {
                right: 0;
                bottom: 14px;
            }

        }

        .mobile-entry {
            .entry-content {
                display: flex;
            }
        }
    }
}