.cc-revoke {
    display: none !important;
} 
.cc-decline { 
    display: none !important; 
} 
.cc-deny{
    display: none !important;
}
.cc-window {
    background-color:$purple-dark !important;  
}

.cc-allow{
    background-color:white !important;  
    color:$purple-dark !important;
}

@media (max-width: 900px) {
    .cc-allow{
        width:100% !important;
    }
    .cc-message{
        padding-top:20px !important;
        color:white !important; 
        padding-bottom:10px !important;
    }
}

 