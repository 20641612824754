@import url("https://use.typekit.net/bgx6kaq.css");


@font-face {
    font-family: 'The Rockstar';
    src: url('../../fonts/TheRockstar.woff2') format('woff2'),
        url('../../fonts/TheRockstar.woff') format('woff'),
        url('../../fonts/TheRockstar.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'The Rockstar Alternate';
    src: url('../../fonts/TheRockstarAlternate.woff2') format('woff2'),
        url('../../fonts/TheRockstarAlternate.woff') format('woff'),
        url('../../fonts/TheRockstarAlternate.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'The Rockstar Italic';
    src: url('../../fonts/TheRockstarItalic.woff2') format('woff2'),
        url('../../fonts/TheRockstarItalic.woff') format('woff'),
        url('../../fonts/TheRockstarItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'The Rockstar Sans';
    src: url('../../fonts/TheRockstarSans.woff2') format('woff2'),
        url('../../fonts/TheRockstarSans.woff') format('woff'),
        url('../../fonts/TheRockstarSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'The Rockstar Sans';
//     src: url('../../fonts/TheRockstarSans_1.woff2') format('woff2'),
//         url('../../fonts/TheRockstarSans_1.woff') format('woff'),
//         url('../../fonts/TheRockstarSans_1.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'The Rockstar';
//     src: url('../../fonts/TheRockstar_1.woff2') format('woff2'),
//         url('../../fonts/TheRockstar_1.woff') format('woff'),
//         url('../../fonts/TheRockstar_1.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'The Rockstar Alternate';
//     src: url('../../fonts/TheRockstarAlternate_1.woff2') format('woff2'),
//         url('../../fonts/TheRockstarAlternate_1.woff') format('woff'),
//         url('../../fonts/TheRockstarAlternate_1.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'The Rockstar';
//     src: url('../../fonts/TheRockstarItalic_1.woff2') format('woff2'),
//         url('../../fonts/TheRockstarItalic_1.woff') format('woff'),
//         url('../../fonts/TheRockstarItalic_1.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
// }


h1 {
    text-align: center;
    font-size: 16pt;
    width: 100%;
    font-weight: 200;
    font-family: "fieldwork";
}

h2 {
    text-align: center;
    font-size: 14pt;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 200;
    font-family: 'The Rockstar Sans';
}

h3 {
    color: $black;
    font-size: 60pt;
    font-weight: 200;
    font-family: 'The Rockstar Sans';
  
}

h4 {}

p {
    line-height: 17pt;
    font-size: 11pt;
    font-family: "fieldwork";
}

.content {
    a {
        color: $purple-light;
        line-height: 17pt;
        font-size: 11pt;
        font-family: "fieldwork";
    } 
}

nav {
    ul {
        li {
            a {
                font-family: "fieldwork";
            }
        }
    }
}