#footer {
    width: 100%;
    height: 330px !important;
    background-color: $purple-dark;
}

footer {
    height: 330px; 
   
    //    width: 50%;
    //    margin: auto;
    //    height:300px;  
    //    background-image:url('../../img/logo-footer.svg'); 
    //    background-size:contain;
    //    background-repeat:no-repeat;  
    .footer-logo {
        width:90%; 
        max-width: 400px;
        margin: auto;

        height: 270px;
        background-image: url(../../img/logo-footer.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .menu-footer-menu-container {

        text-align: center;
        height:30px;

        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                display: inline;
                margin-left: 10px;
                margin-right: 10px;

                a {
                    color: white;
                    font-size: 11pt;
                }
            }
        }
    }
}


@media (max-width: 900px) {
    .footer-logo {
        width:70%;  
    
    }

}