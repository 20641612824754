.page-id-190 {

    h1 {
        text-align: left;
        font-size: 18pt;
        font-family: "fieldwork";
        text-align: left;
        line-height: 20pt;
        margin-bottom: 50px;
    }
    h2 {
        color: $purple-light;
        font-size: 16pt;
        text-align: left;
        position: relative;
        text-transform: none;
        font-family: "fieldwork";
 
    }

    /* NAVIGATION */

    nav ul li {
        &:after {
            color: $purple-light;
        }

        a {
            color: $purple-light;
        }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: $purple-light;
    }


    .scroll-header {
        nav ul li {
            &:after {
                color: white;
            }

            a {
                color: white;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: white;
        }
    }
    .content{
        padding-bottom:100px;
    }
}