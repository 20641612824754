.erklaervideos-content {
    height: 100%;
    width: 80%;
    max-width:1200px;
    margin:auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;

    &>div {
        position: relative;
        width: 100%;
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    h3 {
        margin: 0;
        // line-height: 65pt;
        text-align: right;
        color: $purple-dark;
        line-height: 40pt;
        margin-bottom: 30px;
    }

    // .headline{
    //     width: 260px;
    //     height:50px;
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     margin-bottom:20px; 
    // }
    // .headline-1{
    //     background-image: url(../../img/erklearvideo-1.svg);
    // }
    // .headline-2{
    //     background-image: url(../../img/erklearvideo-2.svg);
    // }
    // .headline-3{
    //     background-image: url(../../img/erklearvideo-3.svg);
    // }
    // .headline-4{
    //     background-image: url(../../img/erklearvideo-4.svg);
    // } 
    // .headline-5{
    //     background-image: url(../../img/erklearvideo-5.svg);
    // }



    p {
        margin-top: 0;
        text-align: right;
    }

    .erklaervideos-text {
        // position: absolute;
        // top: 20%;
        width: 50%;
        padding: 20px;
        // left: 45%;
    }

    video {
        width: 50%;
        outline: none;
    }

}

.erklaervideos-content.reverse {
    &>div {
        flex-direction: row-reverse;

        p,
        h3 {
            text-align: left;
        }
    }

}

@media (max-width: 900px) {

    .erklaervideos-content {
        flex-direction: column;

        &>div {
            flex-direction: column;
        }

        .erklaervideos-text {
            // position: relative;
            width: 100%;
            // left:0;
            // right:0; 

        }

        video { 
            width: 70% !important;
            height: 50%;
            outline: none;
        }

        p,
        h3 {
            text-align: center;
            width:100%;
            margin:auto;
            line-height: 20pt;
          
        }
        h3{
            margin-bottom: 10px;
            font-size: 30pt;
        }
    }

    .erklaervideos-content.reverse {
        &>div {
            flex-direction: column;
            video {
                width: 50%; 
                outline: none;
            }
            p,
            h3 {
                text-align: center;
                width:100%;
                margin:auto; 
                line-height: 20pt;
            }
            h3{
                margin-bottom: 10px;
                font-size: 30pt;
            }
        }
    }
}