body {
  font-family: 'Open Sans', sans-serif !important;
}

.cc-banner {
  .cc-deny {
    display: none !important;
  }
}

img {
  width: 100%;
}

#wrapper {
  // max-width: 1200px;
  // margin: auto;
  width:100%;
  height:100%; 
  overflow:hidden; 
}

.content{
  width:80%;   
  max-width:1000px;
  margin:auto; 
  padding-top: 200px; 

}


video{
  outline:none;
}

@media (max-width: 782px) {
 
}

.sixteen-nine {
  position: relative;
  overflow: hidden;
}

.sixteen-nine:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 56.25%;
} 

.sixteen-nine>.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
}


/** VIDEO FORMATS **/  
.nine-sixteen {
  position: relative;
  // overflow: hidden;
}

.nine-sixteen:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 177%;
} 

.nine-sixteen>.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
}


.four-three {
  position: relative;
  overflow: hidden;
}

.four-three:before {
  display: block;
  content: " ";
  width: 100%;
  padding-top: 75%;
}

.four-three>.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


/** lightbox **/



/* MediumLightbox style */

.scrollbar-measure {
	width: 100px;
	height: 100px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
}

.img {
	display: block;
	margin: auto;
	max-width: 100%;
}


.aspectRatioPlaceholder .img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}



.zoom-effect .img{
	cursor: pointer;
	cursor: -webkit-zoom-in;
	backface-visibility: hidden;
}

.zoom-effect .zoomImg,
.zoom-effect .zoomImg-wrap {
	position: relative;
	z-index: 900;
	transition: all 300ms;
}

.zoom-effect .zoomImg-wrap--absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.zoom-effect .zoomImg {
	cursor: pointer;
	cursor: -webkit-zoom-out;
}


.zoom-effect .zoomOverlay.show{
	opacity: 1;
}

.zoom-effect .zoomOverlay {
	z-index: 800;
	background: #fff;
	position: absolute;
	transition: opacity 300ms;
	opacity: 0;
	cursor: pointer;
	cursor: -webkit-zoom-out;
}
