.page-id-66 {
    h1 {
        text-align: left;
    }

    nav ul li {
        &:after {
            color: $purple-light;
        }

        a {
            color: $purple-light;

        }
    }

    .scroll-header {
        nav ul li {
            &:after {
                color: white;
            }

            a {
                color: white;
            }
        }
    }

    #lightbox {
        position: fixed;
    }

    h1 {
        font-size: 18pt;
        font-family: "fieldwork";
        text-align: left;
        line-height: 20pt;
    }


    h2 {
        font-size: 18pt;
        font-family: "fieldwork";
        text-align: left;
    }

    .protagonistin {
        width: 100%;
        display: flex;
        flex-direction: row;
        // justify-content: center;
        // align-items: center;
        // align-content: center; 
        margin-top: 100px;
        margin-bottom: 100px;

        .image-protagonistin {
            width: 40%;
            margin-right: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .content-protagonistin {
            width: 60%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-content: center;

            position: relative;

            &>div {
                position: absolute;
                width: 100%;
                left: -300px;
                opacity: 0;
            }

            h2 {
                margin: 0;
                position: relative;
                width: auto;
                display: inline-block;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: -50px;
                    top: 0px;
                    height: 40px;
                    width: 40px;
                    background-image: url(../../img/divers-icon.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .btn-more {
                width: 200px;
                display: block;
                background-color: $purple-light;
                color: white;
                cursor: pointer;
                text-align: center;
                padding: 10px;
            }
        }
    }

    .protagonistin.reverse {
        flex-direction: row-reverse;

        .content-protagonistin {
            &>div {
                position: absolute;
                width: 100%;
                left: auto;
                right: -300px;
                opacity: 0;

            }

            h2 {
                text-align: right;
                float: right;
                margin: 0;

                &:after {
                    content: '';
                    display: none;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: -50px;
                    top: 0px;
                    height: 40px;
                    width: 40px;
                    background-image: url(../../img/divers-icon.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                }
            }

            p {
                text-align: right;
                float: right;
            }

            .btn-more {
                float: right;
            }
        }

        .image-protagonistin {
            margin-right: 0;
            margin-left: 20px;
        }

    }




    @media (max-width: 900px) {
        .protagonistin {
            flex-direction: column;

            .image-protagonistin {
                width: 100%;
                margin: 0;
                display: block;
            }



            .content-protagonistin {
                width: 100%;
                margin-top: 30px;
                display: block;

                &>div {

                    position: relative;
                }

                .btn-more {
                    width: 100%;
                    padding-left:0;
                    padding-right:0;
                }
            }
        }

        .protagonistin.reverse {
            flex-direction: column;

            .image-protagonistin {
                width: 100%;
                margin: 0;
                display: block;
            }

            .content-protagonistin {
                width: 100%;
                margin-top: 30px;
                display: block;

                &>div {

                    position: relative;
                }

                .btn-more {
                    width: 100%;
                    padding-left:0;
                    padding-right:0; 
                }
            }
        }

    }



    @media (max-width: 600px) {
        .btn-more {
          display:none !important; 
        }

    }


}