.error404 {
    .content {
        height: calc(100vh - 530px);

        h1 {
            font-size: 100pt;
            margin-bottom: 0;
            text-align: left;
        }
    }

}

@media (max-width: 900px) {
    .error404 {
        .content {
            h1 {
                font-size: 45pt;
            }
        }
    }
}