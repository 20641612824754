.page-id-45{
    .content{
        padding-bottom:100px;
    }
    h1 {
        text-align: left;
    }

    /* NAVIGATION */

    nav ul li {
        &:after {
            color: $purple-light;
        }

        a {
            color: $purple-light;
        }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: $purple-light;
    }


    .scroll-header {
        nav ul li {
            &:after {
                color: white;
            }

            a {
                color: white;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: white;
        }
    }


    /* END NAVIGATION */

    h1 {
        font-size: 18pt;
        font-family: "fieldwork";
        text-align: left;
        line-height: 20pt;
        margin-bottom: 50px;
    }

    h2 {
        color: $purple-light;
        font-size: 16pt;
        text-align: left;
        position: relative;
        text-transform: none;
        font-family: "fieldwork";
        padding-left: 8px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: -30px;
            opacity: 0;
            transform: scale(.4);
            top: 5px;
            height: 30px;
            width: 30px;
            background-image: url(../../img/divers-icon.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            -webkit-transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -moz-transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -ms-transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            -o-transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transition: all .25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }

    h2.showStar {
        &:before {
            transform: scale(1);
            opacity: 1;
        }
    }


    p {
        padding-left: 50px;
        text-align: justify;

    }

    .animation-wrapper {
        p {
            position: relative;
            left: 500px;
            opacity: 0;
        }
    }


    .sources {
        padding-bottom: 100px;

        h3 {
            margin-top: 50px;
            font-size: 16pt;
            font-family: "fieldwork";
            text-align: left;
            line-height: 20pt;
            color: $purple-light;
            font-weight: 600;
            text-transform: none;
        }

        a {
            font-size: 9pt;
            line-height: 14pt;
        }

        p {
            padding-left: 0;
            font-size: 9pt;
            line-height: 14pt;
            color: #2f2f2f;
        }
    }


    .seperator { 
        width: 100%;
        height: 8px;
        background-color: $purple-light;
        display: block;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    @media (max-width: 900px) {
        h2 {

            margin-left: 30px;
            // font-size: 35pt; 
        }

        p {
            padding-left: 0;
        }

    }
} 