.single {
    .entry {
        top: 0;
    }

    .category-content {
        padding-bottom: 100px;
    }

    /* NAVIGATION */

    nav ul li {
        &:after {
            color: $purple-light;
        }

        a {
            color: $purple-light;
        }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: $purple-light;
    }


    .scroll-header {
        nav ul li {
            &:after {
                color: white;
            }

            a {
                color: white;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: white;
        }
    }



    /* END NAVIGATION */

    // .mobile-categories,
    // .desktop-categories{ 
    //     display:flex;
    // }
    @media (max-width: 900px) { 
         
        .mobile-vid{
            display:block !important; 
        }
        .entry-content {
            display:block !important; 
            position: relative !important;
            .entry-hover{
                position: relative !important;
            }
        } 
    }
} 