#intro {

    background-image: url('../../img/map.svg');
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: center;

    #intro-content {
        height: 100%;
        width: 80%;
        max-width: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;

        p {
            color: $purple-dark;
            font-weight: 400;
            font-size: 16pt;
            line-height: 1.75;
            position: relative;
            top: 100px;
            opacity: 0;
        }

        h2 {
            color: $purple-dark;
            font-family: "fieldwork";
            font-size: 20pt;
            text-align: left;
            position: relative;
            left: -200px;
            opacity: 0;
            // font-size:18pt;
            // line-height: 1.75;
        }

        #more {
            display: flex;
            justify-content: center;
            align-items: center;
            vertical-align: middle;
            margin: auto;
            border-radius: 50%;
            color: white;
            width: 130px;
            height: 130px;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100 */
            background: $gradient-start;
            /* Old browsers */
            background: -moz-linear-gradient(top, $gradient-start 0%, $gradient-end 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(top, $gradient-start 0%, $gradient-end 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, $gradient-start 0%, $gradient-end 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=0);
            /* IE6-9 */
            opacity: 0;
            transform: scale(.4);
            -webkit-transition: all .5s ease-in-out;
            -moz-transition: all .5s ease-in-out;
            -ms-transition: all .5s ease-in-out;
            -o-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
        }

        .scale-in {
            opacity: 1 !important;
            transform: scale(1) !important;
        }

    }


    @media (max-width: 900px) {
        #intro-content {
            p {
                font-size: 12pt;
                line-height: 1.2;
            }

            h2 {
                color: $purple-dark;
                font-family: "fieldwork";
                font-size: 18pt;
                text-align: left;

            }
        }
    }

    @media (max-width: 600px) {
        #intro-content {
            #more {
                display: none;
            }
        }
    }
} 
