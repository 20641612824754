#diversland-content {
    background-color: $purple-light;
    width: 100%;
    height: 100%;
    position: relative;

    overflow: hidden;

    // background-image: url(../../img/dummy-stage-min.jpg); 
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center; 
}

#headerVideo {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
}




#headerVideoMobile {
    display: none;
}

@media (max-width: 900px) {
    #headerVideo {
        display: none;
    }


    #headerVideoMobile {
        // display: block;
        // position: absolute;
        // top: 0;
        // margin-left: auto;
        // margin-right: auto; 
        // left: 0;
        // right: 0;
        // min-width: 100%;
        // min-height: 100%;
        &::-webkit-media-controls-panel {
            display: none!important;
            -webkit-appearance: none;
          }
          
          &::--webkit-media-controls-play-button {
            display: none!important;
            -webkit-appearance: none;
          }

          &::-webkit-media-controls-start-playback-button {
            display: none!important;
            -webkit-appearance: none;
          }
          
           

        display: block;
        position: absolute;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;

        width: 100%;
        min-width: 130%;
    }

    .canvas {
        display: block;
        position: absolute;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        min-width: 100%;
    }

}