.page-id-47 {

/* NAVIGATION */

nav ul li {
    &:after {
        color: $purple-light;
    }

    a {
        color: $purple-light;
    }
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    background-color: $purple-light;
}


.scroll-header {
    nav ul li {
        &:after {
            color: white;
        }

        a {
            color: white;
        }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: white;
    }
}


/* END NAVIGATION */
    .team {
        margin-top: 100px;
        margin-bottom: 100px;
        padding-top: 0;
    }

    figure.wide {
        width: 120%;
        margin-left: -10%;
        margin-top: 100px;

        img {
            width: 120%;
            max-width: 120%;
        }
    }

    h2 {
        font-size: 18pt;
        font-family: "fieldwork";
        text-align: left;
        line-height: 20pt;
    }


    .team-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;

        margin: auto;

        .teammember {
            margin: 1%;
            width: 32%;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            display: block;
            position: relative;

            opacity: 0;
            transform: scale(0.4);

            .teammember-overlay {

                position: absolute;
                top: 5%;
                left: 5%;
                right: 5%;
                bottom: 5%;

                background-color: rgba(255, 255, 255, 0.5);
                opacity: 0;

                -webkit-transition: opacity .5s ease-in-out;
                -moz-transition: opacity .5s ease-in-out;
                -ms-transition: opacity .5s ease-in-out;
                -o-transition: opacity .5s ease-in-out;
                transition: opacity .5s ease-in-out;


                h2 {
                    font-size: 18pt;
                    font-family: "fieldwork";
                    text-align: center;
                    padding: 10px;
                    width: 90%;

                }

                p {
                    padding: 10px;
                    padding-top: 0;
                    text-align: center;
                    margin: 0;

                }
            }

            &:hover .teammember-overlay {
                opacity: 1;
            }

            .social {
                position: absolute;
                height: 40px;
                bottom: 20px;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                align-content: center;

                .social-icon {
                    width: 40px;
                    height: 40px;
                    margin-left: 5px;
                    margin-right: 5px;
                    cursor: pointer;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }

                .twitter {
                    background-image: url(../../img/icon-twitter.svg);
                }

                .insta {
                    background-image: url(../../img/icon-insta.svg);
                }
            }
        }
    }



    @media (max-width: 1200px) {
        .team-group {
            align-items: flex-start;

            .teammember {
                cursor: auto;

                .teammember-overlay {
                    position: relative;
                    width: 100%;
                    opacity: 1;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;

                    p {
                        padding-bottom: 37px;
                    }

                    h2 {
                        padding: 0;
                        width: 100%;
                    }

                    .social {
                        position: relative;
                        height: 30px;

                        .social-icon {
                            width: 30px;
                            height: 30px;

                        }
                    }
                }
            }


        }
    }


    @media (max-width: 800px) {
        .team-group {
            flex-direction: column;

            .teammember {
                margin: 0;
                width: 100%;
                margin-bottom: 100px;

                .teammember-overlay {
                    position: relative;
                    width: 100%;
                    opacity: 1;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;

                    p {
                        padding-bottom: 37px;
                    }

                    h2 {
                        padding: 0;
                        width: 100%;
                    }

                    .social {
                        position: relative;

                        height: 40px !important;

                        .social-icon {
                            width: 40px !important;
                            height: 40px !important;

                        }
                    }

                }


            }
        }


    }
}