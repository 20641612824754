header {
    //position: absolute;
    position: fixed;
    z-index: 100;
    height: 150px;
    //background-color: #ddd;
    width: 100%;

    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;

    #logo {
        width: 300px;
        height: 100%;
        display: block;
        background-image: url(../../img/logo-diversland-purple.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        float: left;

        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
    }

    #header-inner {
        width: 80%;
        height: 100%;
        max-width: 1200px;
        margin: auto;
    }

    nav {
        float: right;
        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;

        .desktop-nav {
            margin-top: 60px;
            -webkit-transition: all .5s ease-in-out;
            -moz-transition: all .5s ease-in-out;
            -ms-transition: all .5s ease-in-out;
            -o-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                display: inline;
                position: relative;
                margin-left: 15px;
                margin-right: 15px;

                padding-left: 5px;
                padding-right: 5px;
                border-radius: 2px;

                &:after {
                    content: '\2219';
                    display: block;
                    position: absolute;

                    right: -23px;
                    top: 0;
                    color: #fff;
                }

                &:last-child {
                    margin-right: 0;

                    &:after {
                        display: none;
                    }
                }


                a {
                    color: white;

                    &:hover {
                        color: $purple-dark;
                    }
                }
            }

            .current_page_item {
                a {
                    color: $purple-dark !important;
                }
            }
        }
    }
}

.mobile-nav {
    display: none;
}


.home header #logo {
    background-image: url(../../img/logo-diversland-white.svg);
}


//wenn menu offen weisses logo


.scroll-header {
    background-color: $purple-light;
    height: 80px;

    #logo {
        width: 200px;
        background-image: url(../../img/logo-diversland-scroll.svg) !important;
    }

    ul {
        li {
            &:hover {
                background-color: $purple-dark;
            }

            a {
                &:hover {
                    color: white;
                }
            }
        }
    }

    .current_page_item {
        background-color: $purple-dark;

        a {
            color: white !important;

            &:hover {
                color: white;
            }
        }
    }



    .desktop-nav {
        margin-top: 25px;
    }
}


.hamburger {
    padding: 8px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    display: none;
    text-align: center;
    padding-top: 15px;
    position: fixed;
    z-index: 1001;

    top: 50px;
    right: calc(10% - 15px);
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.hamburger:hover {
    opacity: 0.7;
}

.hamburger.is-active:hover {
    opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
    background-color: white;
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 40px;
    height: 2px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}


.hamburger--squeeze .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}



.hamburger.scroll-hamburger {
    top: 15px;

    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
        background-color: white !important;
    }


    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {

        background-color: white !important;

    }

}



#menu-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(136, 124, 124, 0.7);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 200;
}

.sidebar-logo {
    position: absolute;
    top: 46px;
    left: 20px;
    width: 150px;
    height: 60px;
    background-image: url(../../img/logo-diversland-scroll.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.scroll-nav {
    .sidebar-logo {
        position: absolute;
        top: 10px;
    }
}

@media (max-width: 1080px) {
    .hamburger {
        display: block;
    }

    nav {
        width: 70px;
        height: 100%;
        display: block;
    }

    .desktop-nav {
        display: none;
    }

    .mobile-nav {
        display: block;
    }

    .mobile-nav {
        // display: none; 
        position: absolute;
        z-index: 1000;
        top: 0;
        right: -100%;
        height: 100%;

        width: 80%;
        max-width: 400px;

        overflow: hidden;
        position: fixed;

        background-color: $purple-light;
        margin-top: 0 !important;
        -webkit-transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -ms-transition: all .25s ease-in-out;
        -o-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;

        .menu-main-menu-container {
            margin-top: 120px;
            -webkit-transition: all .5s ease-in-out;
            -moz-transition: all .5s ease-in-out;
            -ms-transition: all .5s ease-in-out;
            -o-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;

            ul {
                padding: 0;

                li {
                    list-style-type: none;
                    padding: 20px;
                    padding-left:35px;
                    // position:relative;
                    // &:before{
                    //     content:'';
                    //     display:block;
                    //     position:absolute;
                    //     left:-15px;
                    //     width:10px;
                    //     height:10px;
                    //     background-image: url(../../img/divers-icon.svg);
                    //     background-size: contain;
                    //     background-repeat: no-repeat;
                    //     background-position: center;
                    // }

                    a {
                        font-size: 16pt;
                    }

                }
            }

        }


    }

    .scroll-nav {
        .menu-main-menu-container {
            margin-top: 80px;
        }
    }


    .mobile-nav.open-nav {
        right: 0;
    }



    .scroll-header {}

    header {

        nav {
            margin-top: 50px;

            .menu-hauptmenu-container {
                background-color: white;
            }

            ul {
                padding: 0;
                width: 100%;

                li {
                    width: 90%;
                    display: block !important;
                    padding: 0;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    margin: 0;
                    padding-left: 5%;
                    padding-right: 5%;

                    border-bottom: 1px solid $purple-dark;
                    border-right: none;

                    a {
                        width: 100%;
                        display: block;
                    }

                }
            }
        }

    }


    .header-nav-open {
        // background-color: $purple-light;
        // #logo {
        //     background-image: url(../../img/logo-diversland-white.svg); 
        // }
    }

    .open-nav {

        // display: block;
        ul {
            li {
                a {
                    color: white !important;
                }
            }

            .current-menu-item {
                a {
                    color: $purple-dark !important;
                }

            }
        }
    }

    .scroll-header {
        nav {
            margin-top: 14px;
        }
    }
}

@media (max-width: 600px) {
    header {
        #logo {
            width: 200px;
        }
    }

    .scroll-header {
     
        #logo { 
            width: 150px;
            background-image: url(../../img/logo-diversland-white.svg) !important;
        }
    }
}