
@import "variables"; 
@import "fonts"; 
@import "structure";  

@import "header"; 
@import "cookie";   
@import "footer";

// @import "pages/404"; 
@import "pages/diversland";  
@import "pages/intro"; 
@import "pages/number";  
@import "pages/date";  
@import "pages/erklaervideos"; 
 
@import "pages/kategorien";  
@import "pages/kategorie";   
  
@import "pages/team";    
@import "pages/protagonistinnen";     
@import "pages/glossar";    
@import "pages/about";   
@import "pages/404";  
@import "pages/impressum";   
@import "pages/datenschutz";      
@import "pages/single";                 
             
// @import "pages/mehrwerkstatt";          
// @import "pages/kundenmeinungen";         
// @import "pages/specials";              
// @import "pages/anfrage";                         
// @import "pages/location";                                 
// @import "pages/datenschutz";       
// @import "global";                                                            