// .scale-in-lightbox{
//     transform: scale(1) !important; 

// }

.lightbox {
    position: absolute;
    //transform: scale(.4);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    z-index: 2000; 
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);

    // -webkit-transition: all .5s ease-in-out;
    // -moz-transition: all .5s ease-in-out;
    // -ms-transition: all .5s ease-in-out;
    // -o-transition: all .5s ease-in-out;
    // transition: all .5s ease-in-out;

    video {
        display: none;
    }

    .playing {
        display: block !important;
        width: 100%;
    }

    .next-btn {
        width: 100%;
        height: 100%;
        ;
        z-index: 100;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: transparent;
        cursor: pointer;
    }


    .lightbox-content {
        width: 100%;

        height: 100%;
        // position:absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;

        h2 {
            font-size: 40pt;
            width: calc(100% - 30px);
            color: white;
            margin-bottom: 20px;
            font-weight: 200;
            position: absolute;
            top: -112px;
            text-align: left;
            font-family: 'The Rockstar Sans';
        }

        .lightbox-content-wrap {
            padding-top: 50px;
            position: relative;
            overflow: hidden;
            // transform:scale(.4);
            // -webkit-transition: all .25s ease-in-out;
            // -moz-transition: all .25s ease-in-out;
            // -ms-transition: all .25s ease-in-out;
            // -o-transition: all .25s ease-in-out;
            // transition: all .25s ease-in-out; 

            .button-close {
                display: block;
                position: absolute;
                cursor: pointer;
                width: 30px;
                height: 30px;
                top: 0;
                right: 0;
                background-image: url(../../img/button-close.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }

            .lightbox-content-inner {
                background-color: white;
                width: 400px;

                // height: 500px;
                //neu
                &>div {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }

            }
        }
    }

    // .swiper-container {
    //     position: absolute;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    // }

    // .swiper-wrapper {
    //     height: 100%;
    //     width: 100%;

    // }

    // .lightBoxVideo {
    //     width: 100%;
    //     height: auto;
    // }

    // .swiper-button-next {
    //     background: transparent;
    //     // background-color: green;
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     z-index: 1;
    //     display: block;

    // }

    #progress-bar {
        width: 100%;
        height: 10px;
        background-color: rgba(255, 255, 255, .4);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;

        #progress {
            position: relative;
            width: 0;
            height: 10px;
            background-color: white;
            -webkit-transition: all .25s ease-in-out;
            -moz-transition: all .25s ease-in-out;
            -ms-transition: all .25s ease-in-out;
            -o-transition: all .25s ease-in-out;
            transition: all .25s ease-in-out;
        }
    }
}

@media (max-width: 600px) {
    .lightbox {
        width: 100%;
        height: 100%;

        .lightbox-content {
            width: 100%;
            height: 100%;
            h2{
                z-index: 20;
                top: 57px;  
            }

            .lightbox-content-wrap {
                width: 100%;
                height: 100%;

                .lightbox-content-inner {
                    width: 100%;
                    height: 100%;
                    video {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }

            }
        }
    }
}
