#number{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100 */
    background: $gradient-start;
    /* Old browsers */
    background: -moz-linear-gradient(top, $gradient-start 0%, $gradient-end 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, $gradient-start 0%, $gradient-end 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, $gradient-start 0%, $gradient-end 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=0);
    /* IE6-9 */ 

    h2{
        font-size:100pt;
        color:white;
        font-weight:200;
        position:relative;  
        text-align:left;
        margin-bottom:0;
        line-height: 63pt; 
        &:before{
            content:'';
            display:block;
            position:absolute;
            width:100px;
            height:200px;
            top:50px;
            opacity:0;
            left:-120px;  
            background-image:url('../../img/icon-number.svg');   
            background-size:contain;
            background-repeat:no-repeat;   

            -webkit-transition: all .75s ease-in-out;
            -moz-transition: all .75s ease-in-out;
            -ms-transition: all .75s ease-in-out;
            -o-transition: all .75s ease-in-out;
            transition: all .75s ease-in-out;
        }  
    }
    p{
        color:white; 
        margin-top:0;
    }
    #number-content{
        max-width: 800px;    
        height:100%;
        display:flex;
        justify-content: center;
        align-items:center;
        margin:auto;

        #number-content-inner{
        margin-top: 80px;
        }
    }

    .animate-icon{
        opacity:1;
        &:before{
            top:-50px;
            opacity:1; 
        } 
    }


    @media (max-width: 900px) {
        h2{
            text-align:center;
            line-height:40pt;
            margin-bottom: 30px;
            opacity:0;
            font-size: 60pt;
            width: 95%;
                margin: auto;
            &:before{ 
                width: 60px;
                height:200px; 
                top:-50px;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
            }    
        }
        .animate-icon{
            opacity:1;
            &:before{ 
                top:-120px;
                opacity:1; 
            } 
        }
        p{ 
            text-align: center;
            width:80%;  
            margin: auto;
            margin-top: 20px;
        }

    }


    @media (max-width: 600px) {
        h2{
            text-align:center;
            line-height:30pt;
            margin-bottom: 30px;
            opacity:0;
            font-size: 35pt;
       
        }
       
        p{ 
            text-align: center;
            width:80%;  
            margin: auto;
            margin-top: 5px;
        }

    }


}   
